import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

import MainLayout from "@/layouts/MainLayout.vue";
import App from "@/modules/_app/classes/App";

import AuthRoutes from "@/modules/auth/routes";

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "sign-in",
    component: MainLayout,

    beforeEnter: (to, from, next) => {
      App.init().finally(() => next());
    },

    children: [...AuthRoutes]
  },

  {
    path: "*",
    component: () => import("@/modules/_app/pages/404.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
