import store from "@/store";

export default class App {
  static async init(): Promise<void> {
    return new Promise(resolve => {
      const language = navigator.language?.toLowerCase();
      const _allowed = new RegExp(/en|ru/);

      if (language && _allowed.test(language)) {
        store.commit("app/locale", language.split("-")[0]);
      } else {
        store.commit("app/locale", "en");
      }

      resolve();
    });
  }
}
