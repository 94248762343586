export default [
  {
    path: "sign-in",
    name: "sign-in",

    meta: {
      title: "auth.sign_in"
    },

    component: () => import("@/modules/auth/pages/SignIn.vue")
  },
  {
    path: "reset-password",
    name: "reset-password",

    meta: {
      title: "auth.forgot_password"
    },

    component: () => import("@/modules/auth/pages/ForgotPassword.vue")
  },
  {
    path: "verify-email",
    name: "verify-email",

    meta: {
      title: "auth.verify_email"
    },

    component: () => import("@/modules/auth/pages/VerifyEmail.vue")
  }
];
