import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";

Vue.use(VueI18n);

function getMessages(files: __WebpackModuleApi.RequireContext) {
  const messages: LocaleMessages = {};

  files.keys().map(file => {
    const matched = file.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = files(file);
    }
  });

  return messages;
}

function loadLocaleMessages(): LocaleMessages {
  const en = require.context(
    "@/assets/i18n/en",
    false,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );

  const ru = require.context(
    "@/assets/i18n/ru",
    false,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );

  return {
    en: getMessages(en),
    ru: getMessages(ru)
  };
}

export default new VueI18n({
  locale: "en",
  fallbackLocale: "ru",
  messages: loadLocaleMessages()
});
