import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

import { localize } from "vee-validate";
import i18n from "@/plugins/i18n";

import en from "vuetify/src/locale/en";
import ru from "vuetify/src/locale/ru";

import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "@/assets/styles/global.sass";

import ProfileIcon from "@/assets/icons/Profile.vue";
import LockIcon from "@/assets/icons/Lock.vue";

export const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#1C1393",
        secondary: "#F5ACAC",
        accent: "#E8E7F4",
        info: "#7871C0",
        error: "#DD1F2C",
        success: "#81CB00",
        warning: "#FFC107",

        lightAccent: "#F6F5FE",

        header: "#200E32",
        disabled: "#B4B3C0",
        switch: "#05022D"
      }
    }
  },
  icons: {
    iconfont: "mdi",
    values: {
      profile: { component: ProfileIcon },
      lock: { component: LockIcon }
    }
  },
  lang: {
    locales: {
      en,
      ru
    }
  }
});

export const changeLanguage = (lang: string) => {
  vuetify.framework.lang.current = lang;
  i18n.locale = lang;
  localize(lang);
};
