import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import appStore from "@/modules/_app/store";

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",

  modules: {
    app: appStore
  }
});
