<template>
  <v-app>
    <Snackbar />

    <v-main class="accent">
      <v-container fill-height>
        <v-row justify="center">
          <v-col
            :style="$vuetify.breakpoint.smAndUp ? 'max-width: 450px;' : null"
          >
            <v-card
              class="rounded-md _box-shadow"
              :loading="fetching"
              :disabled="fetching"
            >
              <v-card-text class="pa-8">
                <p class="text-center mb-8"><Logo /></p>
                <RouterView />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-footer color="transparent" class="justify-center pa-4" app>
      <small class="info--text">&copy; 2022 Profit1 Systems Ltd.</small>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "MainLayout",

  components: {
    Logo: () => import("@/layouts/components/Logo.vue"),
    Snackbar: () => import("@/modules/_app/components/Snackbar.vue")
  },

  computed: {
    fetching: vm => vm.$store.getters["app/fetching"]
  }
};
</script>
