<template>
  <RouterView :key="$route.fullPath" />
</template>

<script>
const TITLE = "Profit1 Affiliate Network";

export default {
  name: "AppLayout",

  watch: {
    $route(to) {
      document.title = `${this.$t(to.meta.title)} :: ${TITLE}` || TITLE;
    }
  },

  created() {
    const preloader = document.getElementById("preloader");
    const timeout = this.timeout;

    window.addEventListener("load", async function() {
      await timeout();

      preloader?.classList.add("fade-out");
      setTimeout(() => preloader?.remove(), 500);
    });
  },

  methods: {
    timeout() {
      return new Promise(resolve => setTimeout(resolve, 500));
    }
  }
};
</script>
