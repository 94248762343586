import { changeLanguage } from "@/plugins/vuetify";

interface State {
  fetching: boolean;
  snack: object;
  locale: string;
}

const appStore = {
  namespaced: true,

  state: () => ({
    fetching: null,
    locale: null,
    snack: null
  }),

  mutations: {
    fetching(state: State, payload: boolean) {
      state.fetching = payload;
    },

    locale(state: State, payload: string) {
      changeLanguage(payload);
      state.locale = payload;
    },

    snack(state: State, payload: object) {
      state.snack = payload;
    }
  },

  getters: {
    fetching(state: State) {
      return state.fetching;
    },

    locale(state: State) {
      return state.locale;
    }
  }
};

export default appStore;
