import { extend, localize } from "vee-validate";

import {
  confirmed,
  email,
  max,
  min,
  regex,
  required
} from "vee-validate/dist/rules";

import en from "vee-validate/dist/locale/en.json";
import ru from "vee-validate/dist/locale/ru.json";

localize({ en, ru });

extend("confirmed", confirmed);
extend("email", email);
extend("max", max);
extend("min", min);
extend("regex", regex);
extend("required", required);

// extend("required", {
//   validate: value => ({ required: true, valid: !!value }),
//   message: "The {_field_} field is required",
//   computesRequired: true
// });
